import { useMemo } from 'preact/hooks';
import { Axis, Transition } from '../../typing/enums';

export const useCarouselUtils = (innerWrapper, props, currentIndex) => {
  return useMemo(() => {
    const getItemWidth = () => {
      return props.itemWidth + (props.axis === Axis.HORIZONTAL ? props.spacing : 0);
    };

    const getItemHeight = () => {
      return props.itemHeight + (props.axis === Axis.VERTICAL ? props.spacing : 0);
    };

    const getItemSize = () => {
      return props.axis === Axis.HORIZONTAL ? getItemWidth() : getItemHeight();
    };

    const getInnerWrapper = () => {
      if (!innerWrapper.current?.base) throw new Error('Carousel innerWrapper is undefined');

      return innerWrapper.current.base;
    };

    const getWrapperSize = () => {
      const wrapper = getInnerWrapper().parentElement;

      return props.axis === Axis.HORIZONTAL ? wrapper.clientWidth : wrapper.clientHeight;
    };

    const getPerView = () => {
      const wrapperSize = getWrapperSize() + props.spacing;
      const itemSize = getItemSize();

      return Math.max(Math.floor(wrapperSize / itemSize), 1);
    };

    const getIndex = (index = 0) => {
      if (index >= props.children.length || index < 0) {
        return currentIndex;
      }
      const { startIndex = 0 } = getCurrentRange();

      const goLeft = index <= startIndex;
      const inViewIndex = getInViewIndex(index);

      return goLeft ? index : index <= inViewIndex ? inViewIndex : index;
    };

    const getMaxIndex = () => {
      return props.children.length - getPerView();
    };

    const getInViewIndex = (index = 0) => {
      if (isInView(index) || index >= props.children.length || index < 0) {
        return currentIndex;
      }
      const { startIndex = 0, endIndex = 0 } = getCurrentRange();

      return index <= startIndex ? index : index - endIndex + startIndex;
    };

    const getCurrentRange = () => ({
      startIndex: currentIndex,
      endIndex: currentIndex + getPerView() - 1
    });

    const isSlidable = nextIndex => {
      return nextIndex >= 0 && nextIndex <= getMaxIndex();
    };

    const isInView = (index = 0) => {
      const { startIndex = 0, endIndex = 0 } = getCurrentRange();

      return index < endIndex && index >= startIndex;
    };

    const translateWrapper = (offset, smooth) => {
      const el = getInnerWrapper();

      // set new transform value
      if (props.transition === Transition.SLIDE && offset <= 0) {
        const maxOffset =
          props.axis === Axis.HORIZONTAL
            ? el.parentElement.offsetWidth - el.offsetWidth
            : el.parentElement.offsetHeight - el.offsetHeight;

        offset = Math.min(0, Math.max(offset, maxOffset));

        setTransition(props.transition === Transition.SLIDE && smooth);
        el.style.transform = `translate3d(${props.axis === Axis.HORIZONTAL ? `${offset}px, 0, 0` : `0, ${offset}px, 0`})`;
      }

      // return current transform value
      const transform = el.style.transform;
      const match = transform?.match(/translate(?:3d)?\((-?[\d.]+px),\s*(-?[\d.]+px)/);

      return match ? parseFloat(props.axis === Axis.HORIZONTAL ? match[1] : match[2]) : 0;
    };

    const setTransition = (addTransition = true) => {
      const el = getInnerWrapper();
      el.style.transition = addTransition ? 'transform 0.3s ease' : 'none';
    };

    return {
      getItemWidth,
      getItemHeight,
      getItemSize,
      getInnerWrapper,
      getWrapperSize,
      getPerView,
      getIndex,
      getMaxIndex,
      getInViewIndex,
      getCurrentRange,
      isSlidable,
      isInView,
      translateWrapper,
      setTransition
    };
  }, [
    props.itemWidth,
    props.axis,
    props.spacing,
    props.itemHeight,
    props.children.length,
    props.transition,
    currentIndex,
    innerWrapper
  ]);
};
