import styled from 'react-emotion';

export const BaseButtonWrapper = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  border: ${props =>
    props.borderWidth ? `${props.borderWidth}px solid ${props.borderColor}` : 'none'};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  background-color: ${props => props.color};
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  padding-top: ${props => Number(props.size) / 4}px;
  padding-bottom: ${props => Number(props.size) / 4}px;
  opacity: ${props => (props.disabled ? 0.2 : 1)};
  transition: opacity 0.25s;
`;
