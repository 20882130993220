import { h } from 'preact';
import { useRef, useState, useCallback } from 'preact/hooks';
import { useGesture } from '@use-gesture/react';
import { Axis } from '../../typing/enums';
import { getButtonProps } from '../../common/props';
import { t } from '../../common/text';
import { useMount } from '../../utils/useMount';
import { CarouselItem } from './CarouselItem/CarouselItem';
import { CarouselNavButton } from './CarouselNavButton';
import { Wrapper, CarouselWrapper, CarouselInnerWrapper } from './Carousel.emotion';
import { useCarouselUtils } from './useCarouselUtils';
import { useEffectWhen } from '../../utils/useEffectWhen';

const preventScrolling = e => e.preventDefault();

const Carousel = props => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mounted, setMounted] = useState(false);

  const innerWrapper = useRef(null);

  const {
    getInnerWrapper, // returns innerWrapper element
    getIndex,
    getInViewIndex,
    getItemSize,
    isSlidable,
    translateWrapper
  } = useCarouselUtils(innerWrapper, props, currentIndex);

  // Initial state (i.e. startIndex)
  useMount(() => {
    goToItem(getInViewIndex(props.index), false);

    return () => setMounted(false); // unmount
  });

  const goToItem = useCallback(
    (index, smooth = true) => {
      const innerWrapperEl = getInnerWrapper();
      if (!innerWrapperEl) return;

      const newIndex =
        index !== undefined
          ? getIndex(index)
          : // If not specified, calculate the index based on the current translate value
            getIndex(Math.max(0, Math.round((-1 * translateWrapper()) / getItemSize())));

      const newTranslateValue = newIndex * getItemSize();
      translateWrapper(-1 * newTranslateValue, smooth);

      setMounted(true);
      setCurrentIndex(newIndex);

      props.onItemSwipe?.(newIndex);
    },
    [getIndex, getInnerWrapper, getItemSize, props, translateWrapper]
  );

  useEffectWhen(
    // Handle props.index change
    () => goToItem(getInViewIndex(props.index)),
    [props.index, goToItem, getInViewIndex],
    (prev, current) => prev[0] !== current[0]
  );

  const nextItem = () => {
    if (currentIndex < props.children.length) {
      goToItem(currentIndex + 1);
      if (props.onNext) {
        props.onNext();
      }
    }
  };

  const prevItem = () => {
    if (currentIndex > 0) {
      goToItem(currentIndex - 1);
      if (props.onPrev) {
        props.onPrev();
      }
    } else {
      goToItem(0);
    }
  };

  const bindGestures = useGesture(
    {
      onDrag: ({ event, movement: [mx], delta: [dx, dy], dragging, memo, cancel }) => {
        const isPinch = event.touches && event.touches.length > 1;
        if (isPinch) return { isPinch: true };

        if (props.swipableItem === false || props.isZoomed) {
          cancel();

          return;
        }

        // Prevent mobile page scroll
        document.addEventListener('touchmove', preventScrolling, { passive: false });

        if (dragging) {
          const currentTranslateValue = translateWrapper();
          const newTranslateValue =
            currentTranslateValue + Math.round(props.axis === Axis.HORIZONTAL ? dx : dy);
          translateWrapper(newTranslateValue, false);
        } else {
          // Drag end
          if (memo?.isPinch) return;
          const threshold = getItemSize() * 0.1;
          const isMainViewer = props.perView === 1;
          if (isMainViewer && Math.abs(mx) > threshold) {
            mx > 0 ? prevItem() : nextItem();
          } else {
            goToItem();
          }
          // Re-enable scroll
          document.removeEventListener('touchmove', preventScrolling);
        }
      }
    },
    {
      drag: {
        axis: props.axis === Axis.HORIZONTAL ? 'x' : 'y'
      }
    }
  );

  const showNext = mounted && isSlidable(currentIndex + 1);
  const showPrev = mounted && isSlidable(currentIndex - 1);
  const showNavigation = props.navigation && (showPrev || showNext);

  const wrapperProps = {
    axis: props.axis,
    itemWidth: props.itemWidth,
    itemHeight: props.itemHeight
  };

  const navProps = {
    ...getButtonProps(props, 'navigation'),
    ...wrapperProps,
    float: props.navigationFloat,
    gutter: props.navigationGutter
  };

  const carouselProps = {
    ...wrapperProps,
    perView: props.perView,
    spacing: props.spacing
  };

  const itemProps = {
    spacing: props.spacing,
    width: `${props.itemWidth}px`,
    height: `${props.itemHeight}px`,
    transition: props.transition,
    axis: props.axis,
    onItemClick: props.onItemClick
  };

  return (
    <Wrapper className={props.className} data-test="carousel" {...wrapperProps}>
      {showNavigation && (props.navigationFloat ? showPrev : true) && (
        <CarouselNavButton
          {...navProps}
          direction="left"
          disabled={!showPrev}
          onClick={prevItem}
          aria-label={t('previous')}
        />
      )}
      <CarouselWrapper
        data-test="carousel-wrapper"
        touchAction={props.touchAction}
        mounted={mounted}
        {...carouselProps}
        {...bindGestures()}
      >
        <CarouselInnerWrapper
          data-test="carousel-inner-wrapper"
          ref={innerWrapper}
          {...carouselProps}
        >
          {props.children.map((node, i) => {
            return (
              <CarouselItem {...itemProps} key={i} index={i} show={i === props.index}>
                {node}
              </CarouselItem>
            );
          })}
        </CarouselInnerWrapper>
      </CarouselWrapper>
      {showNavigation && (props.navigationFloat ? showNext : true) && (
        <CarouselNavButton
          {...navProps}
          direction="right"
          disabled={!showNext}
          onClick={nextItem}
          aria-label={t('next')}
        />
      )}
    </Wrapper>
  );
};

export default Carousel;
